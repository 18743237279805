import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const Report = () => {
  const [accountingYearId] = useState(() =>
    localStorage.getItem("accountingYear")
  );
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Initialize the default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const fetchPdf = async () => {
      if (!accountingYearId) {
        setError("Accounting year ID is missing.");
        setIsLoading(false);
        return;
      }

      try {
        const token = localStorage.getItem("token");
        const apiUrl = process.env.REACT_APP_API_URL;

        const response = await fetch(
          `${apiUrl}/me/company/report/${accountingYearId}/show`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/pdf",
            },
          }
        );

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        } else {
          // Attempt to parse error message from response
          let errorMessage = `Error: ${response.statusText}`;
          try {
            const errorData = await response.json();
            if (errorData.message) {
              errorMessage = `Error: ${errorData.message}`;
            }
          } catch (parseErr) {
            console.error("Error parsing error response JSON:", parseErr);
          }
          setError(errorMessage);
        }
      } catch (err) {
        console.error("Fetch error:", err);
        setError("An unknown error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPdf();
  }, [accountingYearId]);

  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {isLoading ? (
        <p>Loading PDF...</p>
      ) : (
        <>
          {pdfUrl ? (
            <div
              style={{
                height: "calc(100vh - 73px)",
                width: "100%",
              }}
            >
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            </div>
          ) : (
            <p>{error}</p>
          )}
        </>
      )}
    </>
  );
};

export default Report;
